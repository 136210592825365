
import { Component, PropSync } from "vue-property-decorator";
import Vue from "vue";
import SideFilter from "@/components/SideFilter";
import { ContactFilter } from "@/models/Gdpr/ContactFilter";

@Component({
    components: { SideFilter }
})
export default class ContactSideFilter extends Vue {
    @PropSync("filter", { type: Object, required: true })
    protected readonly filterModel!: ContactFilter;

    protected resetToDefault(): void {
        this.filterModel.firstName = "";
        this.filterModel.lastName = "";
        this.filterModel.firmName = "";
        this.filterModel.street = "";
        this.filterModel.zipCode = "";
        this.filterModel.city = "";
        this.filterModel.vin = "";
        this.filterModel.email1 = "";
        this.filterModel.email2 = "";
        this.filterModel.email3 = "";
        this.filterModel.email4 = "";
        this.filterModel.email5 = "";
        this.$emit("applyFilter");
    }

    protected applyFilter(): void {
        this.$emit("applyFilter");
    }
}
