
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import DynamicTitle from "@/components/DynamicTitle";
import { PaginationResult } from "@/models/PaginationResult";
import { ContactListDto } from "@/models/Gdpr/ContactListDto";
import { contactModule } from "@/store/modules/contact";
import { ContactGetters } from "@/store/modules/contact/getters";
import { ContactActions } from "@/store/modules/contact/actions";
import { PaginationInfo } from "@/models/PaginationInfo";
import { ContactFilter } from "@/models/Gdpr/ContactFilter";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { Routes } from "@/router/routes";
import ContactSideFilter from "@/components/ContactSideFilter";
import { userHasClaims } from "@/services/userUtils";
import { GDPR_EDITING } from "@/consts/customClaims";

@Component({
    components: { DynamicTitle, ConfirmationDialog, ContactSideFilter },
    computed: {
        ...contactModule.mapGetters({
            paginationData: ContactGetters.PaginationData,
            isLoading: ContactGetters.IsLoading
        })
    },
    methods: {
        ...contactModule.mapActions({
            loadContactsWithPagination:
                ContactActions.LoadContactsWithPagination,
            deleteContact: ContactActions.DeleteContact
        })
    }
})
export default class GdprList extends Vue {
    private readonly loadContactsWithPagination!: (
        payload: PaginationInfo & ContactFilter
    ) => Promise<void>;
    private readonly deleteContact!: (contactId: number) => Promise<void>;

    protected readonly paginationData!: PaginationResult<ContactListDto>;
    protected readonly isLoading!: boolean;
    protected headers = [
        { text: "Id", value: "id", sortable: false, filterable: false },
        { text: "Name", value: "name", sortable: false, filterable: false },
        { text: "Email", value: "email", sortable: false, filterable: false },
        {
            text: "Country",
            value: "countryCode",
            sortable: false,
            filterable: false
        },
        {
            text: "Address",
            value: "address",
            sortable: false,
            filterable: false
        },
        { text: "Vehicle", value: "vins", sortable: false, filterable: false },
        {
            text: "No.cases",
            value: "caseAmount",
            sortable: false,
            filterable: false
        },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    protected contactFilter: ContactFilter = {};

    private selectedItemId: number | null = null;
    protected deleteDialog = false;
    openContact(contactModel: ContactListDto): void {
        if (contactModel.id) {
            this.$router.push(Routes.GdprEdit + contactModel.id);
        }
    }
    deleteItem(contactModel: ContactListDto): void {
        if (this.isAllowedToDelete) {
            this.selectedItemId = contactModel.id;
            this.deleteDialog = true;
        }
    }
    confirmDeleteContact(): void {
        if (this.selectedItemId) {
            this.deleteContact(this.selectedItemId);
        }
    }
    applyFilter(): void {
        this.getDataFromApi();
    }
    protected options: { page?: number; itemsPerPage?: number } = {};

    @Watch("options")
    updatedOptions(): void {
        this.getDataFromApi();
    }

    getDataFromApi(): void {
        const { page, itemsPerPage } = this.options;

        if (!page || !itemsPerPage) {
            this.loadContactsWithPagination({
                take: 15,
                skip: 0,
                ...this.contactFilter
            });
            return;
        }

        this.loadContactsWithPagination({
            skip: (page - 1) * itemsPerPage,
            take: itemsPerPage,
            ...this.contactFilter
        });
    }

    getAddressString(contact: ContactListDto): string {
        const result: string[] = [];
        if (contact.zipCode) result.push(contact.zipCode);
        if (contact.city) result.push(contact.city);
        if (contact.street) result.push(contact.street);
        if (contact.houseNumber) result.push(contact.houseNumber);
        return result.join(" ");
    }

    get isAllowedToDelete(): boolean {
        return userHasClaims([GDPR_EDITING]);
    }
}
